<template>

    <component v-if="component" :is="component" :data="data" @applyAction="applyAction" />

</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {

    name: 'VaPlayer',

    data () {

        return {

            component: null

        }
    },

    computed: {

        ...mapState(['vastate', 'data']),

        stateid: {
            get () { return this.$store.state.stateid; },
            set (stateid) { this.$store.commit('setStateid', stateid); }
        },

        loader () {

            let vastate = this.vastate || null;

            let map = {
                'step'       : 'VaStep',
                'decision'   : 'VaDecision',
                'record'     : 'VaRecord',
                'records'    : 'VaRecords',
                'summary'    : 'VaSummary'
            };

            let cmp = map[vastate] || 'Va404';

            return () => import(`@/components/${cmp}.vue`);
        }
    },

    beforeRouteEnter (to, from, next) {

        let stateid = to.params.stateid || 0;

        next(_this => { _this.stateid = stateid; });
    },

    beforeRouteUpdate (to, from, next) {

        this.stateid = to.params.stateid || 0;

        next();
    },

    watch: {

        '$route' () { this.stateid = this.$route.params.stateid || 0; },

        stateid (newStateid) { this.loadState(newStateid); },

        data: function(data) {

            data['stateid'] = parseInt(this.stateid, 10) || 0;

            this.setData(this.stateid, data);
        }

    },


    methods: {

        ...mapActions(['loadState', 'applyAction']),

        setData (stateid, data) {

            let title = data.title ? `| ${data.title}` : '';

            document.title = `Telkom ${title}`;

            this.loader()

                .then(() => {
                    this.component = () => this.loader();
                })

                .catch(() => {
                    this.component = () => import('@/components/Va404.vue');
                });
        }

    }

}
</script>
